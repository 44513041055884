.treeItem {
    white-space: nowrap;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.treeMenuGroup {
    margin-left: -20px;
}
