.product-hierarchy-tooltip {
    position: absolute;
    width: 300px;
    height: 350px;
    border: 1px solid cornflowerblue;
    overflow: hidden;
    pointer-events: none;
    transition: opacity 1s;
}

.product-hierarchy-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.product-hierarchy-tooltip p {
    margin: 5px;
    white-space: nowrap;
}
