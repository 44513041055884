.finderResultsMaster {
    background-color: #a2a2a2;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.finderResultsRow {
    background-color: #d2cece;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 16px;
}


.finderRoot {
}
.finderSearch {

}
.finderMaster {
    background-color: #a2a2a2;
    margin-bottom: 5px;
}
.finderSearchRow {
    background-color: #d2cece;
    padding-top: 5px;
    padding-bottom: 5px;
}
.finderSearchRowInput {
  width: 500px !important;
}
.finderResults {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -9px;
}
.finderCard {
    background-color: #969696;
    padding-left:5px; 
    padding-right: 5px; 
    overflow-x: hidden;
    overflow-y: auto;
}


::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

    ::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
    }

    ::-webkit-scrollbar-thumb:active {
        background: #000000;
    }

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
}

    ::-webkit-scrollbar-track:hover {
        background: #666666;
    }

    ::-webkit-scrollbar-track:active {
        background: #333333;
    }

::-webkit-scrollbar-corner {
    background: transparent;
}

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

    .react-contextmenu.react-contextmenu--visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 9999;
    }

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

    .react-contextmenu-item.react-contextmenu-item--active,
    .react-contextmenu-item.react-contextmenu-item--selected {
        color: #fff;
        background-color: #20a0ff;
        border-color: #20a0ff;
        text-decoration: none;
    }

    .react-contextmenu-item.react-contextmenu-item--disabled,
    .react-contextmenu-item.react-contextmenu-item--disabled:hover {
        background-color: transparent;
        border-color: rgba(0,0,0,.15);
        color: #878a8c;
    }

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}

    .react-contextmenu-item--divider:hover {
        background-color: transparent;
        border-color: rgba(0,0,0,.15);
    }

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

    .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
    }

        .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
            content: "▶";
            display: inline-block;
            position: absolute;
            right: 7px;
        }

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}
