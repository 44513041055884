/*.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    padding-left: 5px;
    padding-right: 5px;
}

.ag-theme-balham .ag-filter-toolpanel-header, .ag-theme-balham .ag-filter-toolpanel-search, .ag-theme-balham .ag-header-row, .ag-theme-balham .ag-status-bar {
    font-size: 12px;
    font-weight: bold;
    color: var(--ag-header-foreground-color,var(--ag-secondary-foreground-color,#164778));
    background-color: lightcyan;
}

.ag-theme-balham .ag-cell, .ag-theme-balham .ag-row > .ag-cell-wrapper {
    padding-left: 5px;
    padding-right: 5px;
}

.ag-theme-balham .ag-row-hover {
    background-color: #dfffdf;
}*/

/*
.ag-theme-balham .ag-column-hover {
    background-color: #dfffdf;
}
*/

/*header styling overrides*/
.ag-theme-balham .ag-filter-toolpanel-header, .ag-theme-balham .ag-filter-toolpanel-search, .ag-theme-balham .ag-header-row, .ag-theme-balham .ag-status-bar {
    color: black;
    /*background-color: lightcyan;*/
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.cell-wrap-text {
    white-space: normal !important;
}

.grid-cell-center {
    text-align: center;
}

.grid-header-cell-center {
    text-align: center;
}

.custom-tooltip {
    position: absolute;
    width: 300px;
    height: 50px;
    border: 1px solid cornflowerblue;
    overflow: hidden;
    pointer-events: none;
    transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.job-completed {
    color: blue !important;
}

.job-failed {
    color: red !important;
}

.job-running {
    color: green !important;
}

.job-warning {
    color: orange !important;
}
