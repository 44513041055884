
.loginBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: sans-serif;
    background: radial-gradient(circle, #e3e4e8, #868ca0);
}

.loginButton {
    float: right;
    margin-left: 200px;
}

.cardSpinner {
    background: radial-gradient(white, #CBD8E2, #CBD8E2);
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


/*//loading animation*/

.cssload-spin-box {
    margin: auto;
    left: 0;
    top: 10px;
    bottom: 0;
    right: 0;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    -webkit-box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    -moz-box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    animation: cssload-spin ease infinite 3.4s;
    -webkit-animation: cssload-spin ease infinite 3.4s;
    -moz-animation: cssload-spin ease infinite 3.4s;
}



@keyframes cssload-spin {
    0%, 100% {
        box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    }

    25% {
        box-shadow: -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166);
    }

    50% {
        box-shadow: -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75);
    }

    75% {
        box-shadow: 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166);
    }
}

@-o-keyframes cssload-spin {
    0%, 100% {
        box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    }

    25% {
        box-shadow: -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166);
    }

    50% {
        box-shadow: -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75);
    }

    75% {
        box-shadow: 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166);
    }
}

@-ms-keyframes cssload-spin {
    0%, 100% {
        box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    }

    25% {
        box-shadow: -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166);
    }

    50% {
        box-shadow: -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75);
    }

    75% {
        box-shadow: 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166);
    }
}

@-webkit-keyframes cssload-spin {
    0%, 100% {
        box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    }

    25% {
        box-shadow: -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166);
    }

    50% {
        box-shadow: -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75);
    }

    75% {
        box-shadow: 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166);
    }
}

@-moz-keyframes cssload-spin {
    0%, 100% {
        box-shadow: 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75);
    }

    25% {
        box-shadow: -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166);
    }

    50% {
        box-shadow: -21px -21px rgb(0,111,166), 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75);
    }

    75% {
        box-shadow: 21px -21px rgb(44,175,75), 21px 21px rgb(0,111,166), -21px 21px rgb(44,175,75), -21px -21px rgb(0,111,166);
    }
}

.homecard {
    width: 380px;
    height: auto;
    padding: 2rem;
    border-radius: 1rem;
    color: white;
    margin: 1rem;
    background: rgba(0, 87, 201, 0.45);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(5px);
    border: 1px solid rgb(87, 159, 255);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.homecard-title {
    margin-top: 0;
    margin-bottom: 0.5rem;
    text-align: left !important;
}

.homecard-parapraph {
    text-align: left !important;
}

.shape {
    position: absolute;
    width: 150px;
    /* top: 0.5rem;
  left: 0.5rem; */
    transform: rotateZ(-30deg);
    top: 0.8rem;
    left: 39rem;
}

.shape2 {
    position: absolute;
    width: 150px;
    /* top: 0.5rem;
    left: 0.5rem; */
    transform: rotateZ(30deg);
    top: 0.8rem;
    left: 71rem;
}

.vertical {
    height: 40vh;
}

.rotated {
    transform: rotateZ(5deg);
}

.homecard-subcard {
    background-color: white;
    border-radius: 3rem;
    color: black !important;
    font-size: 0.7rem;
    padding: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 76px;
    height: 76px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 3rem;
}


/*//Overrides*/

.navbar {
    /*height: 61px;*/
    background-color: #efefef;
}

.navbar navbar-default {
    padding-top: 0.2rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0.2rem !important;
    padding-left: 2rem !important;
    background-color: #d3d3d3;
}

.navbar-default {
    border-bottom: 2px #878787 solid;
    height: 66px;
}

.navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto !important;
    margin-top: -8px !important;
}

.nav-link dropdown-toggle {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
}
