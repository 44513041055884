/* Example of overriding a bootstrap class (we are using bootstrap spacing classes for consistency, you have to use !important) */
@media (min-width: 1025px) {
    .narrowOnLargerScreen.pl-5 {
        padding-left: 15vw !important;
    }

    .narrowOnLargerScreen.pr-5 {
        padding-right: 15vw !important;
    }
}

@media (min-width: 1366px) {
    .narrowOnLargerScreen.pl-5 {
        padding-left: 20vw !important;
    }

    .narrowOnLargerScreen.pr-5 {
        padding-right: 20vw !important;
    }
}
